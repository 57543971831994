<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-12">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-trim
                                  v-maxlength
                                  maxlength="45"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="eventGroupList.length">
                        <FormRow>
                          <template v-slot:label>ジャンル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="genreId"
                                  v-model="searchForm.genreId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in genres"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col" v-if="!eventGroupList.length">
                        <FormRow>
                          <template v-slot:label>ジャンル</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="genreId"
                                  v-model="searchForm.genreId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in genres"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="eventGroupList.length">
                        <FormRow>
                          <template v-slot:label>イベントグループ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="group"
                                  v-model="searchForm.group"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in eventGroupList"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>店舗グループ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  name="groupId"
                                  v-model="searchForm.groupId"
                                >
                                  <option value="">すべて</option>
                                  <option
                                    v-for="item in groupList"
                                    :key="item.id"
                                    :value="item.id"
                                  >{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">検索</button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :checkedKey="checkedKey"
                    :data-table="dataTable"
                    :labels="labels"
                    @select-items="handleSelectedItems"
                    @handle-change="handleChange"
                    :count="pointStoreCount"
                    ref="table"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="pointStoreCount"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :searchConditions="searchConditions"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer v-if="$permission.isGmoOrOffice() && !this.isClientChild">
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
              <div class="form-group">
                <div class="form-group-item">チェックした全てへ</div>
                <div class="form-group-item">
                  <input
                    :class="{ 'form-control w-60': true, 'is-error': errors.pointStore }"
                    type="text"
                    v-decimal
                    :disabled="!pointStoreCount"
                    v-model="form.pointStore"
                  />
                </div>
                <div class="form-group-item">%追加</div>
              </div>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="handleAddCommonPoint" button-text="登録する" :disabled="!pointStoreCount"/>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { isPercentWithMinMaxValue } from '@/helpers/validators';
//mixins
import nav from '@/mixins/nav/point';
import checkall from '@/mixins/plugin/checkall';
import search from '@/mixins/plugin/search';
import error from '@/mixins/plugin/error';
//components
import FormRow from '@/components/FormRow.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
//helpers
import { roundedFirstDecimal } from '@/helpers/formatData';

export default {
  data: function() {
    return {
      pageName: 'ポイント',
      parentModulePath: 'parentPoint/getPointStoreList',
      childModulePath: 'childPoint/getPointStoreList',
      searchFields: ['name', 'genreId', 'groupId', 'group'],
      searchConditions: {},
      selectedItems: {
        allFlag: false,
        ids: [],
      },
      checkedKey: 'id',
      searchForm: {},
      form: {},
      itemList: [],
    };
  },
  mixins: [nav, checkall, search, error],
  validations() {
    let form = {
      pointStore: {}
    }
    if (!this.isSelectedItemsExist) {
      form.pointStore = { noSelectedItem: () => false };
    } else {
      if (this.form.pointStore === 0) {
        form.pointStore = { pointRequired: required };
      } else if (this.form.pointStore) {
        form.pointStore = { minMaxPercent: isPercentWithMinMaxValue(0.1, 999.9) }
      }
    }
    return { form };
  },
  components: {
    FormRow,
    TableWithCheckbox,
    PaginationLog,
  },
  computed: {
    ...mapGetters({
      parentPointStoreList: 'parentPoint/pointStoreList',
      parentPointStoreCount: 'parentPoint/pointStoreCount',
      childPointStoreList: 'childPoint/pointStoreList',
      childPointStoreCount: 'childPoint/pointStoreCount',
      genres: 'common/genres',
      groupList: 'group/groupList',
      hasShopRole: 'auth/hasShopRole',
      eventGroupList: 'event/eventGroupList',
      isClientChild: 'auth/isClientChild'
    }),
    isSelectedItemsExist() {
      return !!this.selectedItems?.ids?.length || !!this.selectedItems?.count;
    },
    pointStoreList() {
      return this.isPointCommon ? this.parentPointStoreList : this.childPointStoreList;
    },
    pointStoreCount() {
      return this.isPointCommon ? this.parentPointStoreCount : this.childPointStoreCount;
    },
    dataTable() {
      return this.pointStoreList.map(item => {
        return {
          ...item,
          genreId: item.genre?.name,
          eventGroup: item.eventGroup?.name,
          pointUpPercent: {
            isInputType: true,
            type: 'text',
            directive: 'decimal',
            value: item.pointPercent,
            label: '%追加',
            id: item.id,
            name: `point${item?.id}`,
            disabled: this.isClientChild
          },
          actionButton: [
            { content: '追加', class: 'btn btn-sm btn-bd-main', function: () => this.addPointPercent(item) },
          ],
        };
      });
    },
    labels() {
      return [
        { isCheckbox: true },
        { key: 'name', name: '店舗名' },
        { key: 'genreId', name: 'ジャンル' },
        { key: 'eventGroup', name: 'イベントグループ', notShow: !this.eventGroupList?.length },
        { key: 'group', name: '店舗グループ' },
        { isInputColumn: true,  key: 'pointUpPercent', name: 'ポイント増加%' },
        ...(!this.isClientChild ? [{ isButtonColumn: true , key: 'actionButton' }] : [])
      ];
    }
  },
  methods: {
    initFormPoint() {
      this.form = {
        pointStore: null
      };
    },
    handleSelectedItems(items) {
      this.selectedItems = items;
      if (!this.selectedItems?.count) {
        this.form.pointStore = null;
      }
    },
    handleChange(id, pointPercent) {
      const changedItem = this.itemList?.find(e => e.id === id);
      if (changedItem) {
        changedItem.pointPercent = pointPercent;
      } else {
        this.itemList.push({ id, pointPercent });
      }
    },
    async addPointPercent(item) {
      const addedItem = this.itemList.find(e => e.id === item.id);
      const itemId = addedItem ? addedItem.id : item.id;
      const setError = (key) => {
        this.$store.dispatch('common/setErrorFields', { ['point' + itemId]: true} );
        this.$message.showError(key);
        if (addedItem) item.pointPercent = addedItem.pointPercent;
      };
      if (addedItem) {
        if (addedItem && addedItem?.pointPercent && (addedItem?.pointPercent < 0.1 || addedItem?.pointPercent > 999.9)) {
          setError('invalidPercent');
          return;
        } else if (addedItem?.pointPercent === 0) {
          setError('invalidPointStore');
          return;
        }
      } else {
        if (item?.pointPercent === 0) {
          setError('invalidPointStore');
          return;
        }
      }
      if (addedItem) item.pointPercent = addedItem.pointPercent;
      const data = {
        pointPercent: roundedFirstDecimal(addedItem ? addedItem?.pointPercent : item?.pointPercent) || null,
        allFlag: 0,
        target: [addedItem ? addedItem?.id : item?.id],
        searchCondition: this.searchConditions
      };
      this.handleAddPointStore(data);
    },
    async handleAddCommonPoint() {
      await this.$store.dispatch('common/resetErrorFields');
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'pointStoreEvent');
      } else {
        this.itemList = [];
        const data = {
          pointPercent: roundedFirstDecimal(this.form.pointStore) || null,
          allFlag: this.selectedItems.allFlag,
          target: this.selectedItems.ids,
          searchCondition: this.searchConditions
        }
        this.handleAddPointStore(data, true);
      }
    },
    async handleAddPointStore(data, isAddCommonPoint = false) {
      const pointStore = this.isPointCommon ? 'parentPoint' : 'childPoint';
      const result = await this.$store.dispatch(`${pointStore}/addPointStore`, data);
      if (result) {
        if (isAddCommonPoint) {
          this.$message.showSuccess('pointCommonCreatedSuccess', false, 'count', result.data);
        } else {
          this.$message.showSuccess('pointCreatedSuccess')
        }
        this.getPointStoreList();
        this.$refs.table.resetCheck();
        this.$refs.pagination.resetPagination();
      }
    },
    getGroupList() {
      if (!this.$permission.isStoreOrGroup() && this.hasShopRole) {
        return this.$store.dispatch('group/getGroupList', { limit: 9999 });
      }
    },
    getPointStoreList() {
      if (this.isPointCommon) {
        return this.$store.dispatch('parentPoint/getPointStoreList', { subdomain: this.subdomain });
      } else {
        return this.$store.dispatch('childPoint/getPointStoreList', {
          subdomain: this.subdomain,
          directory: this.$route.params.directory
        });
      }
    },
    getEventGroupList() {
      const actionName = this.isPointCommon ? 'getEventGroupList' : 'getChildEventGroupList';
      return this.$store.dispatch(`event/${actionName}`, this.subdomain);
    }
  },
  mounted() {
    const loading = this.$loading.show();
    this.initForm();
    this.initFormPoint();
    Promise.all([
      this.$store.dispatch('common/getGenres'),
      this.getEventGroupList(),
      this.getGroupList()
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
};
</script>

<style scoped>
.w-60 {
  width: 60px !important;
}
</style>
